<template>
  <el-form ref="form" :model="form" label-width="80px">
    <el-form-item label="报告名称">
      <el-input v-model="form.name"></el-input>
    </el-form-item>

    <el-form-item label="报告时间">
      <el-col :span="11">
        <el-date-picker type="date" placeholder="选择日期" v-model="form.date1" style="width: 100%;"></el-date-picker>
      </el-col>
      <el-col class="line" :span="2">-</el-col>
      <el-col :span="11">
        <el-time-picker placeholder="选择时间" v-model="form.date2" style="width: 100%;"></el-time-picker>
      </el-col>
    </el-form-item>

    <el-form-item label="报告类型">
      <el-radio-group v-model="form.resource">
        <el-radio label="心电报告"></el-radio>
        <el-radio label="影像报告"></el-radio>
        <el-radio label="血液报告"></el-radio>
      </el-radio-group>
    </el-form-item>

    <el-form-item label="报告上传">
      <el-upload
          class="upload-demo"
          action="https://jsonplaceholder.typicode.com/posts/"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :file-list="form.fileList"
          :limit="2"
          :on-exceed="handleExceed"
          list-type="picture">
        <el-button size="small" type="primary">点击上传</el-button>
        <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb,最多上传2张报告</div>
      </el-upload>
    </el-form-item>

    <el-form-item label="报告备注">
      <el-input type="textarea" v-model="form.desc"></el-input>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="onSubmit">立即上传</el-button>
<!--      <el-button>取消</el-button>-->
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: "reportUpload",
  data() {
    return {
      form: {
        name: '',
        date1: '',
        date2: '',
        resource: '',
        fileList: [],
        desc: '',
      }
    }
  },
  methods: {
    onSubmit() {
      console.log('submit!');
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 2 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
  }
}
</script>

<style scoped>

</style>